<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button :to='{name: "products.packages"}' variant="primary" size="sm">
          Back to Packages
        </b-button>

        <b-button @click="remove()" variant="danger" size="sm" class="ml-1">
          Delete
        </b-button>
      </template>
    </PageMenu>

    <div class="page-with-menu">
      <b-card no-body v-if="!packageIsLoading">
        <b-tabs card>
          <b-tab title="Package" active>
            <PackageForm :pk="pk"/>
          </b-tab>
          <b-tab title="Products" lazy>
            <PackageProducts :pk="pk" @onPackageUpdate="updatePackageData"/>
          </b-tab>
          <b-tab title="Prices" lazy>
            <PackagePrices :pk="pk"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
    components: {
      PageMenu: () => import('@/common/components/PageMenu'),
      PackageForm: () => import('./../../components/Package.Form'),
      PackageProducts: () => import('./../../components/Package.Products'),
      PackagePrices: () => import('./../../components/Package.Prices'),
    },
    data(){
      return {
        page: {
            title: "Loading...",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Product Management",
                url: this.$router.resolve({name: "products"}).href
            },
            {
                title: "Packages",
                url: this.$router.resolve({name: "products.packages"}).href
            },
        ],
        pk: null,
        packageIsLoading: false,
      }
    },
    methods: {
      getPackage(packageId){
        this.packageIsLoading = true;
        return this.$api.get(`products/package/${packageId}`).then(({data}) => {
          this.pk = data;
          this.packageIsLoading = false;
          this.page.title = this.pk.name;
          return this.pk;
        })
        .catch(e => {
          console.log("cannot fetch package",e)
        });
      },

      remove(){
        if(confirm("Are you sure you want to delete this package?")){
          this.$api.delete(`products/package/${this.pk.id}`).then(() => {
            this.$router.push({name: "products.packages"});
          });
        }
      },

      updatePackageData(pk){
        this.pk = pk;
      }
    },
    mounted(){
      this.getPackage(this.$route.params.packageId).then(pk => {
        this.pk = pk;
      });
      this.$emit('set-sidebar',{active: "products"});
    },
};
</script>
